import React, { useState, useContext } from "react"
import styled from "styled-components"
import axios from "axios"
import auth from "../../auth/auth"
import { AppContext } from "../../contexts/AppContext"
import { Link, navigate } from "gatsby"
import useButtonLoader from "../../hooks/useButtonLoader"
import { NotificationManager } from "react-notifications"
import { Icon } from "rsuite"
import {
  COLOR_DEFAULT_TEXT_DARK,
  COLOR_DEFAULT_TEXT_LIGHT,
  COLOR_SEARCH_INPUT_TEXT,
  COLOR_DEFAULT_TEXT_DISABLED,
} from "../../constants/colors"
import {
  MOBILE,
  LARGE,
  MEDIUM,
  TAB,
  LANDSCAPE,
} from "../../constants/breakpoints"
import {
  PROP_FONT_SECONDARY
} from "../../constants/props"

const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  font-family: ${PROP_FONT_SECONDARY};
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: ${MOBILE.publicPages.signin.width}vw;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    width: ${LANDSCAPE.publicPages.signin.width}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: ${TAB.publicPages.signin.width}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.publicPages.signin.width}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.publicPages.signin.width}px;
  }
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  margin: 10px auto;
  width: 100%;
`

const FormHeading = styled.h3`
  padding-top: 1.42vh;
  display: flex;
  justify-content: center;
  color: #eeeeee;
  font-weight: normal;
  margin: 0;
  ::selection {
    color: none;
    background: none;
  }
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: ${MOBILE.publicPages.signin.font.heading}px;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    font-size: ${LANDSCAPE.publicPages.signin.font.heading}px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.publicPages.signin.font.heading}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    font-size: ${MEDIUM.publicPages.signin.font.heading}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    font-size: ${LARGE.publicPages.signin.font.heading}px;
  }
  margin-bottom: 25px;
`
const FormInput = styled.input`
  height: 50px;
  width: 100%;
  border: 1px solid #989dad;
  border-radius: 10px;
  outline: none;
  box-shadow: none;
  color: #fff;
  margin: 5px 0px;
  background: none;
  padding: 10px;
  font-size: 18px;
  ::placeholder {
    color: ${COLOR_SEARCH_INPUT_TEXT};
  }
`

const FormHelpText = styled.div`
  margin: 0;
  padding: 10px 15px;
  font-size: 20px;
  color: ${COLOR_DEFAULT_TEXT_LIGHT};
  & a {
    color: ${COLOR_DEFAULT_TEXT_LIGHT};
    &:hover {
      text-decoration: none;
      color: ${COLOR_DEFAULT_TEXT_LIGHT};
    }
  }
`
const ForgotPasswordText = styled.div`
  margin: 0;
  padding: 0px;
  text-align: right;
  font-size: 16px;
  color: ${COLOR_DEFAULT_TEXT_LIGHT};
  & a {
    color: ${COLOR_DEFAULT_TEXT_LIGHT};
    &:hover {
      text-decoration: none;
      color: ${COLOR_DEFAULT_TEXT_LIGHT};
    }
  }
`
const FormSubmitButton = styled.button`
  background-color: #ffffff;
  height: auto;
  font-size: 18px;
  border-radius: 5px;
  align-self: flex-end;
  color: ${COLOR_DEFAULT_TEXT_DARK};
  ::selection {
    color: none;
    background: none;
    border: none;
  }
  :disabled {
    color: ${COLOR_DEFAULT_TEXT_DISABLED};
  }

  padding: 5px 10px;
  @media (min-width: ${TAB.minWidth}px) {
    padding: 5px 30px;
  }
`
const ErrorMessage = styled.p`
  display: block;
  opacity: ${props => (props.hasData ? "1" : "0")};
  color: #9e0101;
  background: #fff;
  font-size: 1.5rem;
  border-left: 4px solid #9e0101;
  padding: 5px 15px;
  transition: opacity 500ms linear;
  @media (min-width: ${TAB.minWidth}px) {
    padding: 10px 30px;
  }
`
const FormHelpers = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  padding-bottom: 20px;
  justify-content: space-between;
`
const PasswordWrapper = styled.div`
  display: flex;
  position: relative;
`
const EyeButton = styled(Icon)`
  font-size: ${MOBILE.box.iconSize}px !important;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 38%;
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    font-size: ${LANDSCAPE.box.iconSize}px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.box.iconSize}px;
  }
`

const ContentLogin = props => {
  let { userState, setUserState } = useContext(AppContext)
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  })

  const [loaderElement, setLoader] = useButtonLoader("Sign in", "Processing...")

  const [submitted, setSubmitted] = useState(false)
  const [errorData, setErrorData] = useState("")
  const [displayError, setDisplayError] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const showError = error => {
    setErrorData(error)
    setDisplayError(true)
    setTimeout(() => {
      hideError()
    }, 3000)
  }

  const hideError = () => {
    setDisplayError(false)
  }

  const validateAndSend = e => {
    setLoader(true)
    e && e.preventDefault()
    if (!formData.email || !formData.password) {
      showError("Please enter email/password")
      // clear error after some time
      setLoader(false)
      return
    }

    axios
      .post(`${process.env.STRAPI_SERVER_URL}/auth/local`, {
        identifier: formData.email,
        password: formData.password,
      })
      .then(response => {
        setSubmitted(true)
        if (response.data.jwt) {
          // Set the user's credentials
          auth.setUserInfo(
            {
              ...response.data.user,
              stationID: response.data.stationID,
              station: response.data.station,
              artist: response.data.artist,
            },
            true
          )

          auth.setToken(response.data.jwt, true)
          const user = response.data.user
          setUserState({
            ...userState,
            isLoggedIn: true,
            confirmed: user.confirmed,
            blocked: user.blocked,
            id: user.id,
            firstname: user.firstname,
            lastname: user.lastname,
            username: user.username,
            email: user.email,
            provider: user.provider,
            role: user.role,
            stationID: response.data.stationID,
            station: response.data.station,
            artist: response.data.artist,
          })
          setLoader(false)
          NotificationManager.success("Logged in successfully!")
          navigate("/")
        }
      })
      .catch(error => {
        // Handle error.
        setLoader(false)
        if (error.response) {
          if (
            error.response.data.message[0].messages[0].id ===
            "Auth.form.error.confirmed"
          ) {
            navigate("/resendconfirmlink?email=" + formData.email)
          }

          showError(
            error.response.data.message[0].messages[0].message.replace(
              "Identifier",
              "Email"
            )
          )
        }
      })
  }

  return (
    <LoginWrapper>
      <Form>
        <FormHeading>Sign in</FormHeading>

        <FormInput
          type="email"
          placeholder="Email"
          onChange={e => setFormData({ ...formData, email: e.target.value })}
          onKeyUp={event => event.keyCode === 13 && validateAndSend()}
        />
        <PasswordWrapper>
          <FormInput
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            onChange={e =>
              setFormData({ ...formData, password: e.target.value })
            }
            onKeyUp={event => event.keyCode === 13 && validateAndSend()}
          />
          <EyeButton
            icon={showPassword ? "eye-slash" : "eye"}
            onClick={() => {
              setShowPassword(!showPassword)
            }}
          />
        </PasswordWrapper>
        <FormHelpers>
          <FormHelpText>
            <Link to="/register">create account</Link>
          </FormHelpText>
          <FormSubmitButton
            ref={loaderElement}
            type="button"
            disabled={submitted}
            onSubmit={validateAndSend}
            onClick={validateAndSend}
          >
            Sign in
          </FormSubmitButton>
        </FormHelpers>
        <ForgotPasswordText>
          <Link to="/forgotpassword">Forgot your password?</Link>
        </ForgotPasswordText>
      </Form>
      <ErrorMessage hasData={displayError ? true : false}>
        {errorData}
      </ErrorMessage>
    </LoginWrapper>
  )
}

export default ContentLogin
