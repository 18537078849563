import React, { useContext } from "react"
import ContentLogin from "../components/ContentLogin"
import { AppContext } from "../contexts/AppContext"

/**
 * Here selected country is just used as a weay to trigger client side rendering so that css works well
 */
const LoginPage = () => {
  let { selectedCountry } = useContext(AppContext)
  return <>{selectedCountry && <ContentLogin />}</>
}

export default LoginPage
